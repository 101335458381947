import { defineStore } from 'pinia'
import appConfig from '@/app.config.js'
import vkIcon from '@/assets/images/socials/vk.svg?url'
import vkIconMob from '@/assets/images/socials/vk-mob.svg?url'
import tgIcon from '@/assets/images/socials/telegram.svg?url'
import tgIconMob from '@/assets/images/socials/telegram-mob.svg?url'

export const useMainStore = defineStore('main', {
  state: () => ({
    mainCoinCode: appConfig.mainCoinCode,
    supportUrl: appConfig.supportUrl,
    mobileMenuStateOpen: false,
    menu: [
      { id: 'tasks', translate: 'menu:tasks', path: '/tasks' },
      { id: 'profile', translate: 'menu:profile', path: 'profile', auth: true },
      { id: 'shop', translate: 'menu:shop', path: '/shop', icon: 'shop' },
      {
        id: 'referrals',
        translate: 'menu:referrals',
        path: '/profile/settings/referrals',
        auth: true
      },
      {
        id: 'battlepass',
        translate: 'menu:pass',
        path: '/battlepass',
        auth: true
      },
      {
        id: 'fantasy',
        translate: 'menu:fantasy',
        path: '/fantasy',
        auth: true
      },
      { id: 'clans', translate: 'menu:clans', path: '/clans' },
      { id: 'pixelbattle', translate: 'menu:pixelbattle', path: '/pixelbattle' }
    ],
    socials: [
      {
        name: 'vk',
        icon: vkIcon,
        iconMobile: vkIconMob,
        link: 'https://vk.com/bc_pari_esports',
        locale: ['ru']
      },
      {
        name: 'telegram',
        icon: tgIcon,
        iconMobile: tgIconMob,
        link: 'https://t.me/bc_pari_esports',
        locale: ['ru', 'en']
      }
    ]
  }),
  actions: {
    setMobileMenuStateOpen(value = false) {
      this.mobileMenuStateOpen = value
    }
  }
})
